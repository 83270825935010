import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "filtersContainer", "triggerOpen", "triggerClose", "triggerSubmit", "filterForm" ]

  connect() {
    if (this.hasFilterFormTarget) {

      const formElements = this.filterFormTarget.elements;
      for (let element of formElements) {
        if (element.name == "type") {
          continue;
        }

        if (element.value !== "") {
          this.openFiltersContainer();
          break;
        }
      }

    }
  }

  openFiltersContainer() {
    this.triggerOpenTarget.classList.add('hidden')
    this.triggerCloseTarget.classList.remove('hidden')
    this.triggerSubmitTarget.classList.remove('hidden')
    this.filtersContainerTarget.style.minHeight = `${this.filtersContainerTarget.scrollHeight}px`
  }

  closeFiltersContainer() {
    this.triggerOpenTarget.classList.remove('hidden')
    this.triggerCloseTarget.classList.add('hidden')
    this.triggerSubmitTarget.classList.add('hidden')
    this.filtersContainerTarget.style.minHeight = "0"
  }
}
