import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "hiddenContainer"]

  connect() {
    this.toggleHiddenContainer();
  }

  toggleHiddenContainer() {
    const isChecked = this.checkboxTarget.checked;
    this.hiddenContainerTarget.style.display = isChecked ? "none" : "block";
  }

  change() {
    this.toggleHiddenContainer();
  }
}
