import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", 'buttonIcon' ]

  toggleContent() {
    this.contentTarget.classList.toggle('hidden');
    this.buttonIconTarget.classList.toggle('rotate-90')
  }
}
